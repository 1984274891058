



























































































import useEvent from "@/use/event";
import useMisc from "@/use/misc";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup(_, { root }) {
    const { getLanguageVersion } = useMisc({ root });
    const { getEventTypeName } = useEvent({ root });

    return { getLanguageVersion, getEventTypeName };
  },
});
