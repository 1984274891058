import { SetupContext } from "@vue/composition-api";
import { v4 as uuid } from "uuid";

export default function useMisc({ root }: Partial<SetupContext>) {
  const getPackageName = (value: string) => {
    switch (value) {
      case "none":
        return root?.$tc("event.panel.company.package.items.none");
      case "main":
        return root?.$tc("event.panel.company.package.items.main");
      case "gold":
        return root?.$tc("event.panel.company.package.items.gold");
      case "silver":
        return root?.$tc("event.panel.company.package.items.silver");
      case "partner":
        return root?.$tc("event.panel.company.package.items.partner");
      case "exhibitor":
        return root?.$tc("event.panel.company.package.items.exhibitor");
    }
  };

  const getLanguageVersion = (value: string) => {
    switch (value) {
      case "polish":
        return root?.$tc("languages.polish");
      case "english":
        return root?.$tc("languages.english");
    }
  };

  const getMessageTemplateType = (value: string) => {
    switch (value) {
      case "registration":
        return root?.$tc("layout.misc.messageTypes.registration");
      case "afterPayment":
        return root?.$tc("layout.misc.messageTypes.afterPayment");
      case "auto":
        return root?.$tc("layout.misc.messageTypes.auto");
      case "remove":
        return root?.$tc("layout.misc.messageTypes.remove");
      case "other":
        return root?.$tc("layout.misc.messageTypes.other");
    }
  };

  const formatCurrency = (amount: number, currency = "PLN") => {
    return new Intl.NumberFormat(root?.$i18n.locale || "pl-PL", {
      style: "currency",
      currency,
    }).format(amount);
  };

  const getMessageType = (value: string) => {
    switch (value) {
      case "Registration":
        return root?.$tc("layout.misc.sendMessageType.registration");
      case "Abstract":
        return root?.$tc("layout.misc.sendMessageType.abstract");
      case "Review":
        return root?.$tc("layout.misc.sendMessageType.review");
      case "Invoice":
        return root?.$tc("layout.misc.sendMessageType.invoice");
      case "Payment":
        return root?.$tc("layout.misc.sendMessageType.payment");
      case "Trigger":
        return root?.$tc("layout.misc.sendMessageType.trigger");
      case "Bulk":
        return root?.$tc("layout.misc.sendMessageType.bulk");
      case "Other":
        return root?.$tc("layout.misc.messageTypes.other");
    }
  };

  const lettersItems = [
    "A",
    "B",
    "C",
    "Ć",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "Ł",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "Ś",
    "T",
    "U",
    "W",
    "V",
    "X",
    "Y",
    "Z",
    "Ź",
    "Ż",
  ];

  const getDiscountType = (value: string) => {
    switch (value) {
      case "percent":
        return root?.$tc(
          "event.panel.sidebar.config.discounts.list.percentType"
        );
      case "amount":
        return root?.$tc(
          "event.panel.sidebar.config.discounts.list.amountType"
        );
      case "constant":
        return root?.$tc(
          "event.panel.sidebar.config.discounts.list.constantType"
        );
    }
  };

  const discountType = [
    {
      name: root?.$tc("event.panel.sidebar.config.discounts.add.percentType"),
      value: "percent",
    },
    {
      name: root?.$tc("event.panel.sidebar.config.discounts.add.amountType"),
      value: "amount",
    },
    {
      name: root?.$tc("event.panel.sidebar.config.discounts.add.constantType"),
      value: "constant",
    },
  ];

  const getSurveyType = (value: string) => {
    switch (value) {
      case "anonymous":
        return root?.$tc("event.panel.modules.surveyModule.anonymusSurvey");
      case "personal":
        return root?.$tc("event.panel.modules.surveyModule.personalSurvey");
    }
  };

  const surveyAnswerType = [
    {
      name: root?.$tc("event.panel.modules.surveyModule.singleChoice"),
      value: "single choice",
    },
    {
      name: root?.$tc("event.panel.modules.surveyModule.multipleChoice"),
      value: "multiple choice",
    },
    {
      name: root?.$tc("event.panel.modules.surveyModule.singleLineText"),
      value: "single line text field",
    },
    {
      name: root?.$tc("event.panel.modules.surveyModule.multilineText"),
      value: "multiline text field",
    },
  ];

  const getSurveyAnswerType = (value: string) => {
    switch (value) {
      case "single choice":
        return root?.$tc("event.panel.modules.surveyModule.singleChoice");
      case "multiple choice":
        return root?.$tc("event.panel.modules.surveyModule.multipleChoice");
      case "single line text field":
        return root?.$tc("event.panel.modules.surveyModule.singleLineText");
      case "multiline text field":
        return root?.$tc("event.panel.modules.surveyModule.multilineText");
    }
  };

  const convertToSlug = (text: string) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const getChoiceType = (value: string) => {
    switch (value) {
      case "single":
        return root?.$tc("layout.misc.singleChoice");
      case "multiple":
        return root?.$tc("layout.misc.multipleChoice");
    }
  };

  const paymentStatus = [
    {
      name: root?.$tc("layout.misc.paymentStatus.notPaid"),
      value: "NOT_PAID",
    },
    {
      name: root?.$tc("layout.misc.paymentStatus.paid"),
      value: "PAID",
    },
    {
      name: root?.$tc("layout.misc.paymentStatus.partialPaid"),
      value: "PARTIAL_PAID",
    },
    {
      name: root?.$tc("layout.misc.paymentStatus.overPaid"),
      value: "OVER_PAID",
    },
  ];

  const reportType = [
    {
      name: root?.$tc("panel.event.report.reportType.general"),
      value: "general",
    },
    {
      name: root?.$tc("panel.event.report.reportType.accommodation"),
      value: "accommodation",
    },
    {
      name: root?.$tc("panel.event.report.reportType.services"),
      value: "services",
    },
    {
      name: root?.$tc("panel.event.report.reportType.abstracts"),
      value: "abstracts",
    },
    {
      name: root?.$tc("panel.event.report.reportType.invoice"),
      value: "invoice",
    },
    {
      name: root?.$tc("panel.event.report.reportType.payments"),
      value: "payments",
    },
  ];

  const printItem = (itemClass: string) => {
    const node: any = document.querySelector(itemClass);

    const mywindow: any = window.open("", "PRINT", "height=800,width=1000");

    mywindow.document.write(node.innerHTML);

    mywindow.document.close();
    mywindow.focus();

    setTimeout(mywindow.print(), 1000);
    mywindow.close();
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  function getNumberOfDays(start: string, end: string) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  const goBack = (link: string, data?: any) => {
    root?.$router.push({
      name: link,
      params: data,
    });
  };

  const checkIfIsOnReserve = (serviceId: string, list: string[]) => {
    if (list.includes(serviceId)) {
      return true;
    } else return false;
  };

  const copyAllCodes = (discountCodes: any) => {
    const codes = discountCodes
      .map((item: { value: string }) => item["value"])
      .join(", ");
    navigator.clipboard.writeText(codes);
    root?.$store.commit("snackbar/PUSH_MESSAGE", {
      id: uuid(),
      color: "primary",
      message: root.$tc("event.panel.config.discounts.add.allCodesCopied"),
    });
  };

  const toCamelCase = (str: string) => {
    return str.replace(/\W+(.)/g, function(match, chr) {
      return chr.toUpperCase();
    });
  };

  return {
    getPackageName,
    getLanguageVersion,
    getMessageTemplateType,
    formatCurrency,
    getMessageType,
    lettersItems,
    getDiscountType,
    discountType,
    getSurveyType,
    surveyAnswerType,
    getSurveyAnswerType,
    convertToSlug,
    getChoiceType,
    paymentStatus,
    reportType,
    printItem,
    getBase64,
    getNumberOfDays,
    goBack,
    checkIfIsOnReserve,
    copyAllCodes,
    toCamelCase,
  };
}
