import { ref, SetupContext } from "@vue/composition-api";

export default function useEvent({ root }: Partial<SetupContext>) {
  const today = ref<string>(new Date().toISOString().split('T')[0])

  const getEventTypeColor = (value: string) => {
    switch (value) {
      case "conference":
        return "deep-purple";
      case "online":
        return "cyan";
      case "course":
        return "pink";
      case "reception":
        return "blue-grey";
      case "siemens":
        return "teal";
      default:
        return null;
    }
  };

  const getEventTypeName = (value: string) => {
    switch (value) {
      case "":
        return root?.$tc('event.type.all')
      case "conference":
        return root?.$tc('event.type.conference')
      case "online":
        return root?.$tc('event.type.online')
      case "course":
        return root?.$tc('event.type.course')
      case "reception":
        return root?.$tc('event.type.reception')
      case "siemens":
        return root?.$tc('event.type.siemens')
      default:
        return null;
    }
  };

  const getEventStatus = (start: string, end: string) => {
    if (new Date(start).getTime() > new Date(today.value).getTime()) return "incoming";
    else if (
      new Date(start).getTime() <= new Date(today.value).getTime() &&
      new Date(end).getTime() >= new Date(today.value).getTime()
    )
      return "in_progress";
    else if (new Date(end).getTime() < new Date(today.value).getTime()) return "complete";
    else return null;
  };

  const getEventStatusColor = (status: string) => {
    switch (status) {
      case "incoming":
        return "blue";
      case "in_progress":
        return "amber";
      case "complete":
        return "green";
      default:
        return null;
    }
  };

  const getEventStatusName = (status: string) => {
    switch (status) {
      case "incoming":
        return root?.$tc('event.status.incoming');
      case "in_progress":
        return root?.$tc('event.status.inProgress');
      case "complete":
        return root?.$tc('event.status.complete');
      case "":
        return root?.$tc('event.status.all');
      default:
        return null;
    }
  };

  return {
    getEventTypeColor,
    getEventTypeName,
    getEventStatus,
    getEventStatusColor,
    getEventStatusName,
  };
}
